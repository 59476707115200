import React from 'react';
import { Helmet } from 'react-helmet';
import Container from '../components/container';
import Header from '../components/header';
import Footer from '../components/footer';
import '../styles/main.scss';

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

class RootIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      companyName: '',
      email: '',
      feedback: '',
      subscribe: true,
      subscribeTo: 'Let me know about future tools, training or activities from the Isobar team.',
      page: '/anonymous',
      showMessage: false
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = { ...this.state };

    delete formData['showMessage'];

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...formData
      })
    })
      .then(() => {
        this.setState({
          showMessage: true
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  handleCheckbox = (e) => this.setState({ [e.target.name]: !this.state[e.target.name] });

  render() {
    const { name, companyName, email, feedback, subscribe } = this.state;

    return (
      <Container>
        <Helmet>
          <title>Product Anonymous</title>
          <link rel="icon" type="image/png" href="/icon.png"></link>
          <meta name="theme-color" content="#fafafa"></meta>
        </Helmet>
        <Header linkToIndex={true} />
        <main>
          <section className="anon-hero">
            <div className="inner">
              <div className="content">
                <h1>Kill your darlings: Lessons on setting up to be objective from the get-go</h1>
                <div className="subcopy">
                  Thank you for your interest in the Product Anonymous event hosted in conjunction
                  with Isobar. We hope you enjoy the content we have created to help you on your
                  journey to being objective and product success.
                </div>
              </div>
            </div>
          </section>
          <section className="index-intro">
            <div className="inner inner--small">
              <h2 className="h3">What we spoke about</h2>
              <p>
                There’s an endless onslaught of competing ideas and requests from executives, team
                leads, customers, and often a lot of personal investment in the ideas being
                suggested. With all this influence, how do you make sure the right signals are heard
                through the noise? We took you through what we identified as core obstacles to being
                objective and a smooth-running product team and a technique we use called the
                Diagnostic, which will help you get the buy-in from sponsors/stakeholders, alignment
                on the objectives &amp; ways of working plus increase the likelihood of avoiding
                Hippos.
              </p>
              <div>
                <br />
                <h4>Links to the good stuff</h4>
                <div className="row" style={{ marginLeft: -10, marginRight: -10 }}>
                  <div className="col col-50">
                    <a
                      href="/resources/Product Anonymous - Isobar.pdf"
                      className="image-link"
                      target="_blank"
                    >
                      <img src="/img/DownloadPresentationDeck0.jpg" alt="presentation deck" />
                      <span>Download presentation deck</span>
                    </a>
                  </div>
                  {/*<div className="col col-33">
                    <a href="#" className="image-link">
                      <img src="/img/DownloadGuideDocument0.jpg" alt="guide document" />
                      <span>Download our guide document</span>
                    </a>
                  </div>*/}
                  <div className="col col-50">
                    <a
                      href="https://miro.com/app/board/o9J_lJkpe-E=/"
                      className="image-link"
                      target="_blank"
                    >
                      <img src="/img/AccessMiroTemplate0.jpg" alt="miro template" />
                      <span>Access template in miro</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="bg-light-grey">
            <div className="inner">
              <h3 className="text-center">Our Speakers</h3>

              <div className="row" style={{ marginLeft: -10, marginRight: -10 }}>
                <div className="col col-50">
                  <div className="speaker page-preview">
                    <div className="content-wrap">
                      <img src="/img/jim.png" alt="Jim O'Malley" />
                      <h5>Jim O'Malley - Head of Strategic Design, Isobar Australia</h5>
                      <p>
                        <a href="https://www.linkedin.com/in/omalamo/" target="_blank">
                          https://www.linkedin.com/in/omalamo/
                        </a>
                      </p>
                      <p>
                        Jim has over 15 years of experience designing and leading the development of
                        innovative products and customer experiences across digital channels. He has
                        worked across multiple industries for start-ups and global brands, including
                        Travel &amp; Leisure, Pharmaceuticals, Health, Superannuation and
                        Automotive.
                      </p>
                      <p>
                        <a href="mailto:jim.omalley@isobar.com">jim.omalley@isobar.com</a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col col-50">
                  <div className="speaker page-preview">
                    <div className="content-wrap">
                      <img src="/img/su.png" alt="Su Lim" />
                      <h5>Su Lim – Associate Design Director, Isobar Australia</h5>
                      <p>
                        <a href="https://www.linkedin.com/in/su-lim/" target="_blank">
                          https://www.linkedin.com/in/su-lim/
                        </a>
                      </p>
                      <p>
                        Su is an Associate Design Director in Isobar’s Products &amp; Services team
                        which exists to create and innovate new products and services that generate
                        value and drive revenue. Day to day, she collaborates with cross-functional
                        teams to uncover opportunities then rapidly prototype, validate and deliver
                        new digital experiences for a range of organisations, from start-ups to
                        enterprises.
                      </p>
                      <p>
                        <a href="mailto:jim.omalley@isobar.com">su.lim@isobar.com</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="form-section" id="anon-form-section">
            <div className="inner inner--small">
              {this.state.showMessage ? (
                <p className="h5">
                  Thank you for your interest! We’ll be in touch with you shortly.
                </p>
              ) : (
                <form
                  name="anonymous-event"
                  method="post"
                  action={`/anonymous`}
                  data-netlify="true"
                  netlify-honeypot="bot-field"
                  onSubmit={this.handleSubmit}
                >
                  <h3 className="text-center">Not sure how to run your own diagnostic session?</h3>
                  <input type="hidden" name="bot-field" />

                  <div className="form-row">
                    <label htmlFor="name"> Name* </label>
                    <input
                      type="text"
                      name="name"
                      required
                      placeholder="Your name"
                      value={name}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-row">
                    <label htmlFor="companyName"> Company Name* </label>
                    <input
                      type="text"
                      name="companyName"
                      required
                      placeholder="Your company name"
                      value={companyName}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-row">
                    <label htmlFor="email"> Email* </label>
                    <input
                      type="email"
                      name="email"
                      required
                      placeholder="Your work email"
                      value={email}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-row">
                    <label htmlFor="feedback"> Feedback </label>
                    <textarea
                      name="feedback"
                      placeholder="What did you think?"
                      value={feedback}
                      onChange={this.handleChange}
                    ></textarea>
                  </div>
                  <div className="form-row">
                    <label className="tickbox" htmlFor="subscribe">
                      <input
                        type="checkbox"
                        name="subscribe"
                        checked={subscribe}
                        value={subscribe}
                        onChange={this.handleCheckbox}
                      />
                      Let me know about future tools, training or activities from the Isobar team.
                    </label>
                    <input
                      type="text"
                      name="subscribeTo"
                      defaultValue={
                        'Let me know about future tools, training or activities from the Isobar team.'
                      }
                      className="hidden"
                    />
                    <input type="text" name="page" defaultValue="/anonymous" className="hidden" />
                  </div>
                  <div className="form-row">
                    <button type="submit">Submit</button>
                  </div>
                </form>
              )}
            </div>
          </section>
        </main>
        <Footer />
      </Container>
    );
  }
}

export default RootIndex;
